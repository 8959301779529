














































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class DeleteButton extends Vue {
    @Prop({})
    action: any;
    state: string = "button"; //button, asking, deleting,
    get displayDialog(): boolean {
        return this.state != "button";
    }

    get displayCancel(): boolean {
        return this.state == "asking";
    }

    get title(): string {
        return "Emin misiniz?";
    }

    get content(): string {
        return "Seçtiğiniz kayıt kalıcı olarak silinecektir. Devam etmek istediğinize emin misiniz?";
    }

    ask() {
        this.state = "asking";
    }

    cancel() {
        if (this.state == 'asking') {
            this.state = "button";
        }
    }

    send() {
        if (this.action) {

            this.state = 'deleting';
            window.setTimeout(() => {
                    this.state = "button";
                    this.$emit('deleted');
                }, 1000
            );
        } else {
            this.$emit('deleted');
            this.$emit('success');
            this.state = "button";
        }
        /*Vue.prototype.$http.delete(this.action).then(() => {
            this.$emit('deleted');
            this.state = 'deleted';
        }).catch((err) => {
        })*/
    }
}
