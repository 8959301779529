












































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AxiosResponse} from "axios";
//import ErrorMessage from "@/components/messages/ErrorMessage.vue";

@Component({
    components: {},
})
export default class FormDialogButton extends Vue {

    @Prop() value: any;
    @Prop() item: any;


    localValue = Object.assign(
        {},
        this.value ? this.value : {},
        this.item ? this.item : {},
    );

    @Watch('value')
    onValueChange() {
        this.localValue = Object.assign({}, this.value ? this.value : {});
    }

    input() {
        console.log("input called, shall emit ", this.localValue);
        this.$emit('input', this.localValue);
        this.$emit('value', this.localValue);
    }

    @Prop() tooltip!: string;
    @Prop() icon!: string;
    @Prop() label!: string;
    @Prop() color!: string;
    @Prop() dark!: string | boolean;
    @Prop() medium!: string | boolean;
    @Prop() isOutlined!: string | boolean;
    @Prop() inline!: string | boolean;
    @Prop() viewOnly!: string | boolean;
    @Prop() action!: string;
    @Prop() initialValue!: object | null | any;
    @Prop() defaultValue!: object | null | any;

    private buttonColor = this.color ? this.color : 'primary'
    // private iconStr = this.icon ? this.icon : 'mdi-message';     icon kullanılmayan butonlarda gereksiz mesaj ikonu çıkarıyordu - kaan
    private iconStr = this.icon ? this.icon : '';

    @Prop({default: "600"}) width!: string | number;

    @Prop() title!: string | null;


    get closeButtonLabel() {
        return (this.isViewOnly ? 'Kapat' : 'Vazgeç');
    }

    get isViewOnly() {
        return this.viewOnly || this.viewOnly === "";
    }

    get isMedium() {
        return this.medium || this.medium === "";
    }

    get isDark() {
        return this.dark || this.dark === "";
    }

    //_width = this.width ? this.width.toString() : "600";


    onInput(val: any) {
        console.log("formDialgButton onInput called with", val);
        this.localValue = val;
    }

    submitButtonLabel!: string;
    dialog: boolean = false;

    onFly: boolean = false;


    initialVal: any;

    get isInline(): boolean {
        return (this.inline && true) || this.inline == '';
    }

    created() {
        this.initialVal = Object.assign({}, this.value);
        if (this.icon == "mdi-plus") {
            this.buttonColor = "green";
        } else if (this.icon == "mdi-pencil") {
            this.buttonColor = "light-blue darken-4";
        }
    }

    onDialogOpen() {
        this.initialVal = Object.assign({}, this.value);
    }

    cancel() {
        this.dialog = false;
        this.$emit('input', this.initialVal);
        this.localValue = {};
        this.$emit('cancel');
        this.$emit('close');
    }

    onSubmit() {
        if (this.action) {
            this.onFly = true;
            this.$http.post(this.action, this.localValue).then((response: AxiosResponse) => {
                this.localValue = response.data;
                this.$emit('success', response.data);
                if (this.value) {
                    this.$emit('input', this.localValue);
                }
                this.$emit('close');
                this.localValue = {};
                this.dialog = false;
            }).catch(() => {
                alert("hata mesajı göstereceğiz cici cici");
            }).finally(() => {
                this.onFly = false;
            })
        } else {

            if (this.value) {
                this.$emit('input', this.localValue);
            }
            this.$emit('success', this.localValue);
            this.localValue = {};
            this.$emit('close');
            this.dialog = false;
        }


    }

    log() {
        console.log("this.value", this.value);
    }
}

