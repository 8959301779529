import {KlasorEntity} from "@/entity/KlasorEntity";

export class KlasorListResponse {
    data!: KlasorEntity[];
}

export function KlasorListResponseProvider(depth:number=3): KlasorListResponse {
    return {
        data: [
            {
                id: 1,
                name: 'Kurumsal Dosyalar',
                parent: null,
                children:[]
            },
            {
                id: 2,
                name: 'Bireysel Dosyalar',
                parent: null,
                children:[]
            },
            {
                id: 3,
                name: 'ABC Bankası',
                parent: {
                    id: 1,
                    name: "Parent",
                    parent: null,
                    children: []
                },
                children:[]
            },
            {
                id: 3,
                name: 'Çalışkan Holding',
                parent: {
                    id: 1,
                    name: "Parent",
                    parent: null,
                    children: []
                },
                children:[]
            },
            {
                id: 4,
                name: 'Diğer Firmalar',
                parent: {
                    id: 1,
                    name: "Parent",
                    parent: null,
                    children: []
                },
                children:[]
            },


            {
                id: 5,
                name: 'Furkan Ticaret',
                parent: {
                    id: 4,
                    name: "Furkan Parent",
                    parent: null,
                    children: []
                },
                children:[]
            },

        ]
    }
}
