



















































import {Component, Vue} from "vue-property-decorator";
import {KlasorListResponseProvider} from "@/services/KlasorService";
import {KlasorEntity} from "@/entity/KlasorEntity";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import Klasor from "@/components/tercihler/Klasor.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";

@Component({
    components: {FormDialogButton, Klasor, DeleteButton}
})
export default class Klasorler extends Vue {

    newItem={};

    items: Array<KlasorEntity> = [];
    eitems: Array<KlasorEntity> = [];
    dialog=false;

    get rootDirs(): Array<KlasorEntity> {
        return this.items.filter((value, index) => value.parent === null)
    }


    mounted() {
        this.loadData();
    }
    klasorEkle(){
        this.dialog=true;
    }

    loadData() {
        this.eitems = KlasorListResponseProvider().data;
        this.items = KlasorListResponseProvider().data;
        this.items
            .filter((value, index) => value.parent !== null)
            .forEach(
                (value, index) => {

                    let parent = this.items.filter(value1 => value1.id == value.parent?.id)[0]
                    if (!parent.children) {
                        parent.children = [];
                    }
                    parent.children.push(value);
                }
            );

    }

}
