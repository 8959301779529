








import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {Component, Mixins} from "vue-property-decorator";

@Component({

})
export default class Klasor extends Mixins(ObjectInputMixin) {

}
